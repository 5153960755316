import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../util/api';

// This changes transaction metadata only
export const TransactionApi = createApi({
  reducerPath: 'TransactionApi',
  tagTypes: ['Transaction'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl()}/api/transaction/`,
    credentials: 'include',
  }),
  endpoints: builder => ({
    sendMessageAndAttachFiles: builder.mutation({
      query: ({ transactionId, body }) => ({
        url: `${transactionId}/send-message-and-attach-files`,
        method: 'POST',
        body,
      }),
    }),
    deleteAttachedFile: builder.mutation({
      query: ({ transactionId, body }) => ({
        url: `${transactionId}/delete-attached-file`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSendMessageAndAttachFilesMutation,
  useDeleteAttachedFileMutation,
  reducer,
} = TransactionApi;
