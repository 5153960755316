import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './LineItemAdditionalItems.module.css';

const LineItemAdditionalItems = props => {
  const { lineItems, intl, transaction } = props;
  const cartAdditionalListings =
    transaction?.attributes?.protectedData?.cartAdditionalListings || {};

  const additionalItems = lineItems.filter(item => item.code.includes('additional-listing'));

  return additionalItems.length > 0 ? (
    <React.Fragment>
      {additionalItems.map((item, i) => {
        const itemId = item.code.replace('line-item/', '');
        const quantity = item.quantity;
        const itemData = cartAdditionalListings[itemId];
        const title = itemData?.title;
        const formattedTotal = formatMoney(intl, item.lineTotal);

        const quantityLabel = `x ${quantity}`;

        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <div className={css.itemImage}>
              <img src={itemData.image} alt={title} />
            </div>
            <div className={css.itemInfo}>
              <p className={css.additionalItemTitle}>{title}</p>
              <p className={css.additionalItemQuantity}>{quantityLabel}</p>
            </div>
            <div className={css.itemValue}>{formattedTotal}</div>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemAdditionalItems.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAdditionalItems;
