import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import remove from 'lodash/remove';
import isNaN from 'lodash/isNaN';
import { currentUserShowSuccess } from './user.duck';
import { denormalisedResponseEntities } from '../util/data';
import { createImageVariantConfig } from '../util/sdkLoader';
import { listingImage } from '../config/configLayout';
import { addMarketplaceEntities } from './marketplaceData.duck';

const RESULT_PAGE_SIZE = 100;

export const CART_ADD = 'add';
export const CART_REMOVE = 'remove';
export const CART_SET = 'set';
export const CART_REMOVE_ITEM = 'remove-item';
export const CART_REMOVE_ALL_SELLER_ITEMS = 'remove-all-seller-items';

export const userCart = currentUser => {
  return (currentUser && currentUser.attributes.profile.publicData.cart) || [];
};

export const isFreeListing = (listing, deliveryMethod = null) => {
  const { price = null, publicData } = listing.attributes;
  const { shippingPriceInSubunitsOneItem } = publicData;
  const priceFree = price ? price.amount === 0 : true;
  const shippingFree =
    deliveryMethod === 'shipping' && shippingPriceInSubunitsOneItem
      ? shippingPriceInSubunitsOneItem === 0
      : true;
  return priceFree && shippingFree;
};

export const isFreeCart = (cartListings, deliveryMethod) => {
  const results = cartListings.map(listing => isFreeListing(listing, deliveryMethod));
  return results.indexOf(false) === -1;
};

// If one product in the cart is allowed to be sent, then all the others are also sent.
// If even one product in the basket can be picked up, then all products can be picked up.
export const cartListingsAvailableDeliveryMethods = listings => {
  const pickupEnabled = listings
    .map(listing => listing.attributes.publicData.pickupEnabled)
    .some(v => v === true);
  const shippingEnabled = listings
    .map(listing => listing.attributes.publicData.shippingEnabled)
    .some(v => v === true);
  return { pickupEnabled, shippingEnabled };
};

const defaultUserQueryParams = {
  expand: true,
  include: ['profileImage'],
  'fields.image': ['variants.square-small', 'variants.square-small2x'],
};

const getUpdatedCart = ({ currentUser, listing, quantity, deliveryMethod, action }) => {
  quantity = parseInt(quantity);
  const listingId = listing.id.uuid;
  const authorId = listing.author.id.uuid;
  const currentCart = userCart(currentUser);
  const cart = JSON.parse(JSON.stringify(currentCart));
  const itemInCart = cart.find(i => i.id === listingId);
  const itemInCartIndex = cart.findIndex(i => i.id === listingId);
  if (action === CART_REMOVE_ALL_SELLER_ITEMS) {
    remove(cart, i => i.authorId === authorId);
    return cart;
  }
  if (itemInCart) {
    if ([CART_ADD, CART_REMOVE, CART_SET].indexOf(action) >= 0 && isNaN(parseInt(quantity))) {
      // ignore unexpexted quantity values
      return cart;
    }
    if (action === CART_ADD) {
      itemInCart.quantity += quantity;
    }
    if (action === CART_REMOVE) {
      const newQuantity = itemInCart.quantity - quantity;
      if (newQuantity <= 0) {
        cart.splice(itemInCartIndex, 1);
      } else {
        itemInCart.quantity = newQuantity;
      }
    }
    if (action === CART_SET) {
      if (quantity <= 0) {
        cart.splice(itemInCartIndex, 1);
      } else {
        itemInCart.quantity = quantity;
      }
    }
    if (action === CART_REMOVE_ITEM) {
      remove(cart, i => i.id === itemInCart.id);
    }
  } else if (action === CART_ADD) {
    cart.push({
      id: listingId,
      authorId: authorId,
      quantity,
      deliveryMethod,
    });
  }

  return cart;
};

const updateCartQueryFn = async (arg, queryApi, _extraOptions, _baseQuery) => {
  const { dispatch, extra: sdk } = queryApi;
  const { listing, quantity, deliveryMethod, action, currentUser } = arg;
  const cart = getUpdatedCart({
    currentUser,
    listing,
    quantity,
    deliveryMethod,
    action,
  });
  const updateValues = {
    publicData: {
      cart,
    },
  };

  const updateProfileResponse = await sdk.currentUser.updateProfile(
    updateValues,
    defaultUserQueryParams
  );
  const entities = denormalisedResponseEntities(updateProfileResponse);
  if (entities.length !== 1) {
    throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
  }
  const updatedCurrentUser = entities[0];
  dispatch(currentUserShowSuccess(updatedCurrentUser));

  const data = updatedCurrentUser;
  return { data };
};

const changedUser = ({ currentUser, cart }) => {
  const { id, type, attributes: attributesRaw, ...relationships } = currentUser;
  const { profile } = attributesRaw;
  const { publicData = {} } = profile;
  const attributes = {
    ...attributesRaw,
    profile: {
      ...profile,
      publicData: {
        ...publicData,
        cart,
      },
    },
  };
  return { id, type, attributes, ...relationships };
};

/*
 Here we use optimistic renewal of the current user.
 Otherwise it doesn't work on Android
*/
const onUpdateCartQueryStarted = async (arg, { dispatch, queryFulfilled }) => {
  const { listing, quantity, deliveryMethod, action, currentUser } = arg;
  const oldCart = userCart(currentUser);
  const cart = getUpdatedCart({
    currentUser,
    listing,
    quantity,
    deliveryMethod,
    action,
  });
  dispatch(currentUserShowSuccess(changedUser({ currentUser, cart })));
  try {
    await queryFulfilled;
  } catch {
    dispatch(currentUserShowSuccess(changedUser({ currentUser, cart: oldCart })));
  }
};

const { aspectRatio: aspectRatioRaw, variantPrefix = 'listing-card' } = listingImage;
const aspectRatio = 1 / eval(aspectRatioRaw); // eslint-disable-line no-eval
const defaultListingParams = {
  include: ['author', 'author.profileImage', 'images', 'currentStock'],
  'fields.image': [
    // Scaled variants for large images
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Cropped variants for listing thumbnail images
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
    `variants.${variantPrefix}-4x`,
    `variants.${variantPrefix}-6x`,

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
};

const fetchCartListingsQueryFn = async (arg, queryApi, _extraOptions, _baseQuery) => {
  const { dispatch, extra: sdk } = queryApi;
  const { currentUser } = arg;
  const cart = userCart(currentUser);
  const page = 1;
  const ids = cart.map(i => i.id);
  const queryParams = {
    ids,
    page,
    perPage: RESULT_PAGE_SIZE,
    'limit.images': 1,
    ...defaultListingParams,
  };
  const response = await sdk.listings.query(queryParams);
  dispatch(addMarketplaceEntities(response));

  const data = denormalisedResponseEntities(response);

  return { data };
};

export const CartApi = createApi({
  reducerPath: 'CartApi',
  tagTypes: ['Cart', 'CartListings'],
  baseQuery: fakeBaseQuery(),
  endpoints: builder => ({
    updateCart: builder.mutation({
      queryFn: updateCartQueryFn,
      invalidatesTags: ['Cart'],
      onQueryStarted: onUpdateCartQueryStarted,
    }),
    fetchCartListings: builder.query({
      queryFn: fetchCartListingsQueryFn,
      invalidatesTags: ['CartListings'],
    }),
  }),
});

export const { useUpdateCartMutation, useFetchCartListingsQuery, reducer } = CartApi;
