import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../../util/api';

export const isAwsS3FileSizeLimitError = error => error?.data?.code === 'LIMIT_FILE_SIZE';

const TAG_TYPE = 'TransactionMessages';

export const AwsS3TransactionMessagesApi = createApi({
  reducerPath: 'AwsS3TransactionMessagesApi',
  tagTypes: [TAG_TYPE],
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl()}/api/aws-s3/transaction-messages/`,
    credentials: 'include',
  }),
  endpoints: builder => ({
    fetchFiles: builder.query({
      query: ({ transactionId }) => `${transactionId}/list-files`,
      providesTags: (result, error, arg) => {
        const { transactionId } = arg;
        return result
          ? [
              ...result.map(({ id }) => ({
                type: TAG_TYPE,
                id: { transactionId, id },
              })),
              TAG_TYPE,
            ]
          : [TAG_TYPE];
      },
    }),
    fetchOwnFiles: builder.query({
      query: ({ transactionId }) => `${transactionId}/list-own-files`,
      providesTags: (result, error, arg) => {
        const { transactionId } = arg;
        return result
          ? [
              ...result.map(({ id }) => ({
                type: TAG_TYPE,
                id: { transactionId, id },
              })),
              TAG_TYPE,
            ]
          : [TAG_TYPE];
      },
    }),
    uploadFile: builder.mutation({
      query: ({ transactionId, file }) => {
        const body = new FormData();
        body.append('Content-Type', file.type);
        body.append('file', file);

        return {
          url: `${transactionId}/upload`,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: async ({ transactionId, id, file }, { dispatch, queryFulfilled }) => {
        const patchFiles = dispatch(
          AwsS3TransactionMessagesApi.util.updateQueryData(
            'fetchOwnFiles',
            { transactionId },
            draftFiles => {
              draftFiles.push({ id, file });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (err) {
          // no action
        }
        patchFiles.undo();
      },
      invalidatesTags: [TAG_TYPE],
    }),
    deleteFile: builder.mutation({
      query: ({ transactionId, id }) => ({
        url: `${transactionId}/delete-file`,
        method: 'POST',
        body: {
          key: id,
        },
      }),
      invalidatesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
  }),
});

export const {
  useFetchFilesQuery,
  useFetchOwnFilesQuery,
  useUploadFileMutation,
  useDeleteFileMutation,
  reducer,
} = AwsS3TransactionMessagesApi;
